.pageHeader{

}


.pageHeaderWrapper{
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 16px;
}
.pageHeader{
    padding: 0;
}
.pageHeader .ant-page-header-heading-title{
    /* color: #fff; */
    font-size: 32px;
    line-height: 32px;
    margin-bottom: 0;
}
.ant-page-header-heading-left{
    -ms-flex-align: flex-end;
    align-items: flex-end;
}



@media only screen and (max-width: 992px){
    .pageHeaderWrapper{
        margin-bottom: 8px;
    }
    .pageHeader .ant-page-header-heading-title{
        font-size: 20px;
        line-height: 20px;
    }
}