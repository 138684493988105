
.ant-layout-header{
    background: transparent;
}

.layoutHeader{
    /* position: fixed; */
    width: 100%;
    display: flex;
    align-items: center;
    z-index: 1;
    padding: 0;

    line-height: 32px;
    color: #000;
}
.layoutHeader .logo{
    padding: 0;
    margin-right: 25px;
    color: #000;
    font-size: 24px;
}

.layoutHeader .ant-menu{
    /* background: #000; */
    background: transparent;
    color: #000;
}



.mobileToggleButton{
    display: none;

    /* position: absolute;
    right: 15px;
    top: 100px;
    z-index: 22; */
}

@media only screen and (max-width: 992px){
    .layoutHeader .ant-menu{
        
    }

    .navWrapper{
        position: absolute;
        left: -300px;
        top: 0;
        z-index: 21;
        opacity: 0;
        display: none;
        background: #ffffff;
        box-shadow: 0 0 15px rgba(0,0,0,0.1);

        height: 100vh; 
        width: 300px;
    }


    .navWrapper.show{
        left: 0;
        opacity: 1;
        display: block;
    }


    .mobileToggleButton{
        display: block;
    }

}


.ant-menu-horizontal > .ant-menu-item > a{
    color: #333333 !important;
}
.ant-menu-horizontal > .ant-menu-item > a:hover,
.ant-menu-horizontal > .ant-menu-item-selected > a{
    /* color: #244ff9; */
}
.ant-menu-item:hover, 
.ant-menu-item-active, 
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, 
.ant-menu-submenu-active, 
.ant-menu-submenu-title:hover,
.ant-menu-horizontal > .ant-menu-item:hover, 
.ant-menu-horizontal > .ant-menu-submenu:hover, 
.ant-menu-horizontal > .ant-menu-item-active, 
.ant-menu-horizontal > .ant-menu-submenu-active, 
.ant-menu-horizontal > .ant-menu-item-open, 
.ant-menu-horizontal > .ant-menu-submenu-open, 
.ant-menu-horizontal > .ant-menu-item-selected, 
.ant-menu-horizontal > .ant-menu-submenu-selected{
    color: #333333 !important;
    border-color: #244ff9 !important;
}

.ant-menu-horizontal > .ant-menu-item{
    transition: border .2s;
}
.ant-menu-horizontal > .ant-menu-item-selected{
    border-bottom: 1px solid #244ff9 !important;
}

.ant-menu-submenu-title{
    padding: 0;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item, 
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu{
    padding: 0 10px;
    margin: 0 10px;
}
.ant-menu-horizontal{
    border-bottom: none;
}

.profileActions{
    margin-left: auto;
    display: flex;
    align-items: center;
}


.profileActions .actionItem{
    margin: 0 10px;
}
.profileActions .actionItem a{
    color: #000;
    display: block;
    /* height: 32px; */
}
.profileActions .actionItem:last-child{
    margin-right: 0;
}


.profileActions .ant-dropdown-link{
    display: flex;
    align-items: center;
}
.profileActions .ant-dropdown-link .anticon{
    font-size: 16px;
    margin-top: 12px;
}


.profileActions button.ant-dropdown-link{
    padding: 0;
    background: none;
    border: none;
    color: #000;
    outline: none;
}
.profileActions button.ant-dropdown-link .anticon{
    margin-top: 5px;
}


.profileActions .profileItem > a{
    padding: 5px 10px;
    background: rgba(0,0,0,0.1);
    border-radius: 25px;
    transition: all 2.s;
}
.profileActions .profileItem > a:hover{
    background: rgba(0,0,0,0.2);
}
.profileActions .profileItem a span.username{
    margin-left: 10px;

    margin-top: 3px;
    margin-right: 0;
    color: #000;
    font-weight: 700;
}


@media only screen and (max-width: 768px){
    
    .profileActions .profileItem > a{
        padding: 0;
        background: none;
        box-shadow: 0 0 10px rgba(0,0,0,0.2);
    }

    .profileActions .profileItem a span.username{
        margin-left: 0;
        display: none;
    } 
}

.ProfileAvatar{}