


.analysis-print-wrapper{}
.analysis-big-title{
    color: #000;
    font-weight: 600;
    margin-bottom: 16px;
    text-align: center;
}
.analysis-print-wrapper .td-title{
    line-height: 14px;
    font-weight: 600;
}
.analysis-print-wrapper .tableFilter{
    font-size: 14px;
    line-height: 18px;
}
.analysis-print-wrapper .ant-table-wrapper .ant-table-thead > tr > th{
    padding: 3px;
    white-space: nowrap;
}
.analysis-print-wrapper .ant-table-wrapper .ant-table-tbody > tr > td.ant-table-cell{
    padding: 0px 3px;
    max-width: 220px;
    white-space: pre-wrap;
}
.analysis-print-wrapper .analysis-value-box{
    border-left: 1px solid #f2f2f2;
    width: 40px;
    height: 20px;
}
.analysis-print-wrapper .analysis-value-box .bigger{
    width: 140px;
}

.analysis-print-wrapper .analysis-inner-title{
    font-weight: 600;
    color: #000;
    font-size: 14px;
}
.analysis-print-wrapper .analysis-footer strong{
    font-weight: 600;
    color: #000;
}
.analysis-print-wrapper .analysis-footer{
    border-top: 1px solid #f2f2f2;
    padding-top: 5px;
}

.analysis-print-wrapper .analysis-note-section{
    min-height: 60px;
    margin: 0;
    padding-top: 5px;
    border-right: 1px solid #f2f2f2;
}
.analysis-print-wrapper .analysis-note-section.border-0{
    border-right: none;
}

@media print {
    .analysis-print-wrapper{
        padding: 0 15px;
        min-height: 1030px;
        display: flex;
        flex-direction: column;
    }
    .analysis-print-wrapper .td-title{}
    .analysis-print-wrapper .tableFilter{
        font-size: 10px;
        line-height: 13px;
    }
    .analysis-print-wrapper .td-title{
        font-size: 10px;
    }
    .analysis-print-wrapper .analysis-inner-title{
        font-size: 10px;
        font-weight: 600;
        color: #000;
    }
    .analysis-print-wrapper .ant-table-wrapper .ant-table-thead > tr > th{
        padding: 0px 3px;
        font-size: 10px;
        font-weight: 600;
        color: #000;
    }
    .analysis-print-wrapper .ant-table-wrapper .ant-table-tbody > tr > td.ant-table-cell{
        padding: 0px 3px;
        font-size: 9px;
    }
    .analysis-print-wrapper .analysis-value-box{
        height: 8px;
    }
    .analysis-print-wrapper .analysis-footer{
        font-size: 10px;
        margin-top: auto;
    }

    /* .analysis-print-wrapper .ant-table-wrapper table { page-break-inside:auto }
    .analysis-print-wrapper .ant-table-wrapper tr    { page-break-inside:avoid; page-break-after:auto }
    .analysis-print-wrapper .ant-table-wrapper thead { display:table-header-group }
    .analysis-print-wrapper .ant-table-wrapper tfoot { display:table-footer-group } */
}