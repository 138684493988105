.login-layout{
    background: #f2f2f2;
    min-height: 100vh;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.loginBrand{
    /* position: absolute;
    top: -32px;
    left: -55px; */
    color: #fff;
    font-size: 32px;
}

.loginCard{
    width: 420px;
    max-width: 100%;
    background: #fff;
    border-radius: 10px;
    padding: 60px;
    /* min-height: 460px; */
    box-shadow: 0 0 15px rgba(0,0,0,0.1);

    position: relative;
}

.loginCard .cardHead{
    margin-bottom: 32px;
}

.loginCard .cardHead h2,
.loginCard .cardHead h3{
    color: #252525;
    margin-bottom: 0;
}
.loginCard .cardHead h2{
    font-size: 32px;
}
.loginCard .cardHead h3{
    font-size: 24px;
}
.loginCard .cardHead span{
    color: #8492a6;
    font-size: 14px;
}

.loginCard .cardContent{}


.formLogin .formGroup{
    margin-bottom: 25px;
}
.formLogin .formGroup label{
    display: flex;
    align-items: flex-end;
    margin-bottom: 5px;
    font-size: 16px;
}
.formLogin .formGroup label > a{
    margin-left: auto;
    font-size: 14px;
    /* color: #8492a6; */
}

.ant-input-affix-wrapper{
    border-radius: 6px;
}
/* .ant-input-affix-wrapper-lg{
} */
.ant-input-prefix{
    margin-right: 10px;
}

.ant-input-affix-wrapper:hover,
.ant-input-affix-wrapper-focused{
    border-color: red;
    box-shadow: none;
}