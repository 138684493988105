@import url(https://fonts.googleapis.com/css2?family=Baloo+2:wght@400;500;600;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body{
    font-family: 'Baloo 2', sans-serif;
    background: #f2f2f2;
}


.warning {
    border: solid 1px #fed700
}

.warning-text {
    background: #fed700;
    padding: .24rem .4rem;
    border-radius: 8px;
    width: 100%;
    display: block;
    text-align: center;
}

.danger {
    border: solid 1px #fe0000
}

.danger-text {
    background: #fe0000;
    padding: .24rem .4rem;
    border-radius: 8px;
    width: 100%;
    display: block;
    text-align: center;
}

.ant-layout{
    position: relative;
    z-index: 1;
}
.ant-layout:before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1;
    pointer-events: none;
    height: 400px;
    background-color: #ffc40a;
    /* border-bottom-left-radius: 2.5rem; */
    border-bottom-right-radius: 2.5rem;
}

.layout-container{
    max-width: 1240px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
}


.layout-content{
    min-height: calc(100vh - 200px);
    padding: 24px 0;
    background: transparent;
    color: #fff;
    /* rgb(255, 255, 255); */
}
@media (max-height:500px){
    .layout-content{
        min-height: 100%;
    }
}




.pageHeaderOptions{
}

.text-button{
    color: #252525;
}
.text-button:hover{
    color: #000;
}


.dash-col{
    padding-bottom: 15px;
}
.ant-card{
    border-radius: 8px;
    box-shadow: 0 0 15px rgba(0,0,0,0.1);
    margin-bottom: 25px;
    height: 100%;
}
.ant-card-head{
    border-radius: 8px 8px 0 0;
}
.ant-card .ant-card-type-inner .ant-card-head-title,
.ant-card .ant-card-head-title{
    padding: 16px 0;
    font-size: 16px;
}

.ant-card-extra a{
    color: #6c0aff;
    font-weight: 500;
}

.ant-card .cardIcon{
    position: absolute;
    right: 25px;
    top: 25px;
}
.ant-card .cardIcon .anticon{
    font-size: 20px;
}

.ant-card-body{
    padding: 25px 25px 10px;
}

/*card types*/
.ant-card.cardTable{
    min-height: 400px;
}




/*modal*/
.ant-modal-content{
    border-radius: 10px;
}
.ant-modal-header{
    border-radius: 10px 10px 0 0;
}
.ant-modal-footer{
    border-radius: 0 0 10px 10px;
}



/*dropdown*/
.ant-dropdown{
    /* box-shadow: 0 0 15px rgba(0,0,0,0.1); */
}
.dropdownContent{
    background: #fff;
    border-radius: 10px;
    padding: 25px;
    min-width: 150px;
    max-width: 400px;
    box-shadow: 0 0 15px rgba(0,0,0,0.1);
}
.dropdownContent .dropdownHeader{
    text-align: center;
    margin-bottom: 15px;
    padding-bottom: 5px;
    font-size: 18px;
    font-weight: 700;
    border-bottom: 1px solid #f2f2f2;
}


@media only screen and (max-width: 481px){
    .ant-dropdown{
        width: 100%;
        left: 0 !important;
        padding: 0 10px;

    }
}



/*ant menu*/
.ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left{
    border-right: none;
}





.text-center{
    text-align: center;
}



/*forms*/
.formButton{
}

.ant-select{
    width: 100%;
}

.ant-radio-group{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.ant-radio-wrapper{
    display: flex;
    align-items: center
}

.profileCardProfile{
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content:center;

}
.profileName{
    display: block;
    font-weight: 700;
    color: #000;
    text-align: center;
    font-size: 20px;
    margin-right: 0;
    width: 160px;
}



.btn-w-100{
    width: 100%;
}






/*widget*/
.widget-statistic-flex{
    display: flex;
    justify-content: space-between;
}

.align-center{
    align-items: center;
}
.align-end{
    align-items: flex-end;
}

.widget-icon{
    font-size: 42px;
    color: #6c0aff;
    opacity: 0.5;
}



.notification-card-list{
    margin-bottom: 15px;
}
.notification-card-list .notification-card{
    display: flex;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #f2f2f2;
}
.notification-card-list .notification-card:last-child{
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
}

.notification-card-list .notification-card .card-icon{
    margin-right: 15px;
}
.notification-card-list .notification-card .card-icon .anticon{
    font-size: 18px;
    margin-top: 3px;
}
.notification-card-list .notification-card .card-content{
    margin-right: 10px;
}
.notification-card-list .notification-card .card-content span{
    display: block;
}
.notification-card-list .notification-card .card-content span.text{
    font-size: 14px;
}
.notification-card-list .notification-card .card-content span.time{
    font-size: 12px;
    color: #6a6a6a;
}


.notification-card-list .notification-card .card-action{
    margin-left: auto;
}

.notification-card-all,
.card-all-button{
    margin-top: 15px;
    text-align: center;
}



/*timeline*/
.ant-timeline-item{
    padding-bottom: 10px;
}
.ant-timeline-item-content > span{
    display: flex;
}
.ant-timeline-item-content > span span.time{
    margin-left: auto;
    font-size: 12px;
    color: #6a6a6a;
}



/*table*/


.ant-table-content{
    width: 100%;
    overflow-x: auto;
    overflow-y: none;
}
@media only screen and (max-width: 992px){
    .ant-table-content{
        width: 100%;
        overflow-x: auto;
        overflow-y: none;
    }
}


.ant-spin-blur{
    opacity: 1 !important;
}
.ant-btn{
    border-radius: 30px;
}

/*custom ant*/
.ant-btn-primary{
    background: #ff8300;
    border-color: #ff8300;
}
.ant-btn-primary:hover,
.ant-btn-primary:focus{
    background: #cc6a00;
    border-color: #cc6a00;
}
.ant-btn-primary:active{
    background: #cc6a00;
    border-color: #cc6a00;
}


/*btn-update-class*/
.btn-success{
    background: #9bd006;
    border-color: #9bd006;
}
.btn-success:hover,
.btn-success:focus{
    background: #7ea70b;
    border-color: #7ea70b;
}
.btn-success:active{
    background: #7ea70b;
    border-color: #7ea70b;
}


/* .ant-table-cell .ant-btn-link{
    color: #f00;
}
.ant-table-cell .ant-btn-link:hover,
.ant-table-cell .ant-btn-link:focus{
    color: rgb(200, 0, 0);
} */

.ant-table-column-sorters{
    white-space: nowrap;
}


.ant-btn.btn-add{
    background: #ffffff;
    border-color: #ffffff;
    color: #000;
}
.ant-btn.btn-list{
    background: #ffffff;
    border-color: #ffffff;
    color: #000;
}



@media only screen and (max-width: 574px){
    .ant-btn.btn-add span{
        display: none;
    }
    .ant-btn.btn-add span.anticon{
        display: block;
    }
}


.quickLinks{
}
.quickLinks .ant-btn{
    height: auto;
    padding: 10px;
    border-radius: 6px;
    width: 100%;
    margin-bottom: 16px;
}
.quickLinks .ant-btn .anticon{
    font-size: 22px;
    display: block;
    margin: 10px 0;
}
.quickLinks .ant-btn span.text{
    font-size: 15px;
    display: block;
}


.btn-next-step{
    margin-top: 30px;
    width: 100%;
}



.formButton{
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    margin-top: 25px;
    /* background: #f9f9f9;
    padding: 15px; */
}



/*tags*/
.ant-tag-has-color,
.ant-tag-has-color a,
.ant-tag-has-color a:hover,
.ant-tag-has-color .anticon-close,
.ant-tag-has-color .anticon-close:hover{
    color: #fff;
}

/*
.ant-tag-has-color{
    color: #000;
}
.status-orange{
    background-color: #fd6205;
    border-color: #fd6205;
} */




@media only screen and (min-width: 1441px){
    .layout-container{
        max-width: calc(100% - 30px);
    }
}

@media only screen and (max-width: 1440px){
    .layout-container{
        max-width: calc(100% - 10px);
    }
}


@media only screen and (min-width: 768px){
    .ant-modal{
        min-width: 700px !important;
    }
}

.row-dragging {
    background: #fafafa;
    border: 1px solid #ccc;
  }

  .row-dragging td {
    padding: 16px;
    visibility: hidden;
  }

  .row-dragging .drag-visible {
    visibility: visible;
  }




.description-table .ant-descriptions-item-label,
.description-table .ant-descriptions-item-content{
    padding: 5px 24px;
}

.mt-1{
    margin-top: 16px;
}
.mt-2{
    margin-top: 32px;
}
.mb-1{
    margin-bottom: 16px;
}
.mb-2{
    margin-bottom: 32px;
}

.ml-1{
    margin-left: 16px;
}
.ml-2{
    margin-left: 32px;
}
.mr-1{
    margin-right: 16px;
}
.mr-2{
    margin-right: 32px;
}


.td-title{
    font-weight: 700;
    color: #000;
}


.custom-tag{
    display: inline-flex;
    align-items: center;
    flex-direction: row-reverse;
}
.custom-tag .anticon{
    margin : 0 5px;
}



.descriptionList.ant-descriptions-bordered .ant-descriptions-item-label,
.descriptionList.ant-descriptions-bordered .ant-descriptions-item-content{
    padding: 3px 16px;
}

.ant-form .react-multi-email,
.ant-form-item .react-multi-email{
    border-radius: 2px;
    padding: 4px 10px;
    border: 1px solid #d9d9d9;
}
.ant-form .react-multi-email [data-tag],
.ant-form-item .react-multi-email [data-tag]{
    font-weight: 400;
}


.test-paketleri-all-select .ant-select-item-option-content{}

.ant-select-dropdown .ant-select-item-option-content{
white-space: normal;
}


.ant-layout-header{
    background: transparent;
}

.layoutHeader{
    /* position: fixed; */
    width: 100%;
    display: flex;
    align-items: center;
    z-index: 1;
    padding: 0;

    line-height: 32px;
    color: #000;
}
.layoutHeader .logo{
    padding: 0;
    margin-right: 25px;
    color: #000;
    font-size: 24px;
}

.layoutHeader .ant-menu{
    /* background: #000; */
    background: transparent;
    color: #000;
}



.mobileToggleButton{
    display: none;

    /* position: absolute;
    right: 15px;
    top: 100px;
    z-index: 22; */
}

@media only screen and (max-width: 992px){
    .layoutHeader .ant-menu{
        
    }

    .navWrapper{
        position: absolute;
        left: -300px;
        top: 0;
        z-index: 21;
        opacity: 0;
        display: none;
        background: #ffffff;
        box-shadow: 0 0 15px rgba(0,0,0,0.1);

        height: 100vh; 
        width: 300px;
    }


    .navWrapper.show{
        left: 0;
        opacity: 1;
        display: block;
    }


    .mobileToggleButton{
        display: block;
    }

}


.ant-menu-horizontal > .ant-menu-item > a{
    color: #333333 !important;
}
.ant-menu-horizontal > .ant-menu-item > a:hover,
.ant-menu-horizontal > .ant-menu-item-selected > a{
    /* color: #244ff9; */
}
.ant-menu-item:hover, 
.ant-menu-item-active, 
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, 
.ant-menu-submenu-active, 
.ant-menu-submenu-title:hover,
.ant-menu-horizontal > .ant-menu-item:hover, 
.ant-menu-horizontal > .ant-menu-submenu:hover, 
.ant-menu-horizontal > .ant-menu-item-active, 
.ant-menu-horizontal > .ant-menu-submenu-active, 
.ant-menu-horizontal > .ant-menu-item-open, 
.ant-menu-horizontal > .ant-menu-submenu-open, 
.ant-menu-horizontal > .ant-menu-item-selected, 
.ant-menu-horizontal > .ant-menu-submenu-selected{
    color: #333333 !important;
    border-color: #244ff9 !important;
}

.ant-menu-horizontal > .ant-menu-item{
    transition: border .2s;
}
.ant-menu-horizontal > .ant-menu-item-selected{
    border-bottom: 1px solid #244ff9 !important;
}

.ant-menu-submenu-title{
    padding: 0;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item, 
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu{
    padding: 0 10px;
    margin: 0 10px;
}
.ant-menu-horizontal{
    border-bottom: none;
}

.profileActions{
    margin-left: auto;
    display: flex;
    align-items: center;
}


.profileActions .actionItem{
    margin: 0 10px;
}
.profileActions .actionItem a{
    color: #000;
    display: block;
    /* height: 32px; */
}
.profileActions .actionItem:last-child{
    margin-right: 0;
}


.profileActions .ant-dropdown-link{
    display: flex;
    align-items: center;
}
.profileActions .ant-dropdown-link .anticon{
    font-size: 16px;
    margin-top: 12px;
}


.profileActions button.ant-dropdown-link{
    padding: 0;
    background: none;
    border: none;
    color: #000;
    outline: none;
}
.profileActions button.ant-dropdown-link .anticon{
    margin-top: 5px;
}


.profileActions .profileItem > a{
    padding: 5px 10px;
    background: rgba(0,0,0,0.1);
    border-radius: 25px;
    transition: all 2.s;
}
.profileActions .profileItem > a:hover{
    background: rgba(0,0,0,0.2);
}
.profileActions .profileItem a span.username{
    margin-left: 10px;

    margin-top: 3px;
    margin-right: 0;
    color: #000;
    font-weight: 700;
}


@media only screen and (max-width: 768px){
    
    .profileActions .profileItem > a{
        padding: 0;
        background: none;
        box-shadow: 0 0 10px rgba(0,0,0,0.2);
    }

    .profileActions .profileItem a span.username{
        margin-left: 0;
        display: none;
    } 
}

.ProfileAvatar{}
.ant-layout-footer{
    padding: 0;
}
.pageHeader{

}


.pageHeaderWrapper{
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 16px;
}
.pageHeader{
    padding: 0;
}
.pageHeader .ant-page-header-heading-title{
    /* color: #fff; */
    font-size: 32px;
    line-height: 32px;
    margin-bottom: 0;
}
.ant-page-header-heading-left{
    align-items: flex-end;
}



@media only screen and (max-width: 992px){
    .pageHeaderWrapper{
        margin-bottom: 8px;
    }
    .pageHeader .ant-page-header-heading-title{
        font-size: 20px;
        line-height: 20px;
    }
}
.antStepsContainer{
    padding: 25px 200px;
}

@media only screen and (max-width: 1024px){
    .antStepsContainer{
        padding: 0;
    }
}
.login-layout{
    background: #f2f2f2;
    min-height: 100vh;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.loginBrand{
    /* position: absolute;
    top: -32px;
    left: -55px; */
    color: #fff;
    font-size: 32px;
}

.loginCard{
    width: 420px;
    max-width: 100%;
    background: #fff;
    border-radius: 10px;
    padding: 60px;
    /* min-height: 460px; */
    box-shadow: 0 0 15px rgba(0,0,0,0.1);

    position: relative;
}

.loginCard .cardHead{
    margin-bottom: 32px;
}

.loginCard .cardHead h2,
.loginCard .cardHead h3{
    color: #252525;
    margin-bottom: 0;
}
.loginCard .cardHead h2{
    font-size: 32px;
}
.loginCard .cardHead h3{
    font-size: 24px;
}
.loginCard .cardHead span{
    color: #8492a6;
    font-size: 14px;
}

.loginCard .cardContent{}


.formLogin .formGroup{
    margin-bottom: 25px;
}
.formLogin .formGroup label{
    display: flex;
    align-items: flex-end;
    margin-bottom: 5px;
    font-size: 16px;
}
.formLogin .formGroup label > a{
    margin-left: auto;
    font-size: 14px;
    /* color: #8492a6; */
}

.ant-input-affix-wrapper{
    border-radius: 6px;
}
/* .ant-input-affix-wrapper-lg{
} */
.ant-input-prefix{
    margin-right: 10px;
}

.ant-input-affix-wrapper:hover,
.ant-input-affix-wrapper-focused{
    border-color: red;
    box-shadow: none;
}
.selectedTests{
    background: #f2f2f2;
    padding: 15px;
}
.antStepsContainer{
  padding: 50px 200px;
}

.antStepsFilter{
  padding: 0 50px;
}

.resultList{
  padding: 15px 30px;
  margin: 50px;
}

.stepActions{
  display: flex;
  justify-content: center;
  margin: 50px 0 0;
}

@media only screen and (max-width: 1024px){
  .antStepsContainer{
      padding: 0;
  }


  .antStepsFilter{
    padding: 0;
  }


  .resultList{
    padding: 15px 5px;
    margin: 25px 0;
  }

}


.steps-content {
    margin-top: 16px;
    border: 1px dashed #e9e9e9;
    border-radius: 2px;
    background-color: #fafafa;
    min-height: 200px;
    text-align: center;
    padding-top: 80px;
  }
  
  .steps-action {
    margin-top: 24px;
  }

.searchAutoComplete{
  padding: 25px 0;
}
.searchAutoComplete h2{
  margin-bottom: 0;
}


.ant-form-item{
  margin-bottom: 15px;
}


.radioContainer{
  padding: 10px;
  margin-top: 10px;
}

.radioContainer .ant-form-item{
  margin-bottom: 5px;
}

.radioContainer .ant-form-item-label{
  padding-bottom: 0;
}
.video-container video{
  height: 100%;
  width: 100%;
}
.qr-print-wrapper{
    display: flex;
    align-items: center;
    min-height: 120px;
}
.qr-print-wrapper .qr-print-img{
    flex: 0 0 120px;
    width: 120px;
    min-height: 120px;
}
.qr-print-wrapper .qr-print-img svg{
    /* flex: 0 0 50px; */
}
.qr-print-wrapper .qr-print-img .qr-print-status-img{
    width: 120px;
}
.qr-print-wrapper .qr-print-img .qr-print-status{
    text-align: center;
}
.qr-print-wrapper .qr-print-img .qr-print-status img{
    width: 32px;
}

.qr-print-wrapper .qr-prin-details{
    margin-left: 10px;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(100% - 130px);
}
.qr-print-wrapper .qr-prin-details span{
    display: block;
    margin-bottom: 3px;
}

/*SCREEN*/
.qr-screen-wrapper .qr-yourCode{
    margin-top: 20px;
}
.qr-screen-wrapper .qr-printButton{
    margin-top: 30px;
}
/*SCREEN*/


@media print {
    .qr-print-wrapper{
        display: flex;
        align-items: center;
        padding: 0 10px;
        page-break-after: always;
        min-height: 190px; /* 50mm karsiligi */
        /* background-color: #f2f2f2; */
    }
    .qr-print-wrapper .qr-print-img{
        flex: 0 0 120px;
        width: 120px;
        min-height: 120px;
        /* background-color: #ddd; */

    }
    .qr-print-wrapper .qr-print-img svg{
        /* flex: 0 0 60px;
        margin-top: 0;
        margin-left: 0; */
    }
    .qr-print-wrapper .qr-prin-details{
        margin-left: 10px;
        /* background-color: #f00; */
    }
    .qr-print-wrapper .qr-prin-details span{
        font-size: 11px;
        line-height: 13px;
        color: #000;
        font-family: "Arial";
    }
    .qr-print-wrapper .qr-prin-details span > strong{
        display: inline-block;
        width: 72px;
    }
}

.sample-status-wrapper .sample-status-card{
    display: flex;
    align-items: center;
    margin: 10px 0;
}
.sample-status-wrapper .sample-status-card img{
    width: 32px;
    flex: 0 0 32px;
    margin-right: 10px;
}



.analysis-print-wrapper{}
.analysis-big-title{
    color: #000;
    font-weight: 600;
    margin-bottom: 16px;
    text-align: center;
}
.analysis-print-wrapper .td-title{
    line-height: 14px;
    font-weight: 600;
}
.analysis-print-wrapper .tableFilter{
    font-size: 14px;
    line-height: 18px;
}
.analysis-print-wrapper .ant-table-wrapper .ant-table-thead > tr > th{
    padding: 3px;
    white-space: nowrap;
}
.analysis-print-wrapper .ant-table-wrapper .ant-table-tbody > tr > td.ant-table-cell{
    padding: 0px 3px;
    max-width: 220px;
    white-space: pre-wrap;
}
.analysis-print-wrapper .analysis-value-box{
    border-left: 1px solid #f2f2f2;
    width: 40px;
    height: 20px;
}
.analysis-print-wrapper .analysis-value-box .bigger{
    width: 140px;
}

.analysis-print-wrapper .analysis-inner-title{
    font-weight: 600;
    color: #000;
    font-size: 14px;
}
.analysis-print-wrapper .analysis-footer strong{
    font-weight: 600;
    color: #000;
}
.analysis-print-wrapper .analysis-footer{
    border-top: 1px solid #f2f2f2;
    padding-top: 5px;
}

.analysis-print-wrapper .analysis-note-section{
    min-height: 60px;
    margin: 0;
    padding-top: 5px;
    border-right: 1px solid #f2f2f2;
}
.analysis-print-wrapper .analysis-note-section.border-0{
    border-right: none;
}

@media print {
    .analysis-print-wrapper{
        padding: 0 15px;
        min-height: 1030px;
        display: flex;
        flex-direction: column;
    }
    .analysis-print-wrapper .td-title{}
    .analysis-print-wrapper .tableFilter{
        font-size: 10px;
        line-height: 13px;
    }
    .analysis-print-wrapper .td-title{
        font-size: 10px;
    }
    .analysis-print-wrapper .analysis-inner-title{
        font-size: 10px;
        font-weight: 600;
        color: #000;
    }
    .analysis-print-wrapper .ant-table-wrapper .ant-table-thead > tr > th{
        padding: 0px 3px;
        font-size: 10px;
        font-weight: 600;
        color: #000;
    }
    .analysis-print-wrapper .ant-table-wrapper .ant-table-tbody > tr > td.ant-table-cell{
        padding: 0px 3px;
        font-size: 9px;
    }
    .analysis-print-wrapper .analysis-value-box{
        height: 8px;
    }
    .analysis-print-wrapper .analysis-footer{
        font-size: 10px;
        margin-top: auto;
    }

    /* .analysis-print-wrapper .ant-table-wrapper table { page-break-inside:auto }
    .analysis-print-wrapper .ant-table-wrapper tr    { page-break-inside:avoid; page-break-after:auto }
    .analysis-print-wrapper .ant-table-wrapper thead { display:table-header-group }
    .analysis-print-wrapper .ant-table-wrapper tfoot { display:table-footer-group } */
}



.analysis-print-wrapper{}
.analysis-big-title{
    color: #000;
    font-weight: 600;
    margin-bottom: 16px;
    text-align: center;
}
.analysis-print-wrapper .td-title{
    line-height: 14px;
    font-weight: 600;
}
.analysis-print-wrapper .tableFilter{
    font-size: 14px;
    line-height: 18px;
}
.analysis-print-wrapper .ant-table-wrapper .ant-table-thead > tr > th{
    padding: 3px;
    white-space: nowrap;
}
.analysis-print-wrapper .ant-table-wrapper .ant-table-tbody > tr > td.ant-table-cell{
    padding: 0px 3px;
    max-width: 220px;
    white-space: pre-wrap;
}
.analysis-print-wrapper .analysis-value-box{
    border-left: 1px solid #f2f2f2;
    width: 40px;
    height: 20px;
}
.analysis-print-wrapper .analysis-value-box .bigger{
    width: 140px;
}

.analysis-print-wrapper .analysis-inner-title{
    font-weight: 600;
    color: #000;
    font-size: 14px;
}
.analysis-print-wrapper .analysis-footer strong{
    font-weight: 600;
    color: #000;
}
.analysis-print-wrapper .analysis-footer{
    border-top: 1px solid #f2f2f2;
    padding-top: 5px;
}

.analysis-print-wrapper .analysis-note-section{
    min-height: 60px;
    margin: 0;
    padding-top: 5px;
    border-right: 1px solid #f2f2f2;
}
.analysis-print-wrapper .analysis-note-section.border-0{
    border-right: none;
}

@media print {
    .analysis-print-wrapper{
        padding: 0 15px;
        min-height: 1030px;
        display: flex;
        flex-direction: column;
    }
    .analysis-print-wrapper .td-title{}
    .analysis-print-wrapper .tableFilter{
        font-size: 10px;
        line-height: 13px;
    }
    .analysis-print-wrapper .td-title{
        font-size: 10px;
    }
    .analysis-print-wrapper .analysis-inner-title{
        font-size: 10px;
        font-weight: 600;
        color: #000;
    }
    .analysis-print-wrapper .ant-table-wrapper .ant-table-thead > tr > th{
        padding: 0px 3px;
        font-size: 10px;
        font-weight: 600;
        color: #000;
    }
    .analysis-print-wrapper .ant-table-wrapper .ant-table-tbody > tr > td.ant-table-cell{
        padding: 0px 3px;
        font-size: 9px;
    }
    .analysis-print-wrapper .analysis-value-box{
        height: 8px;
    }
    .analysis-print-wrapper .analysis-footer{
        font-size: 10px;
        margin-top: auto;
    }

    /* .analysis-print-wrapper .ant-table-wrapper table { page-break-inside:auto }
    .analysis-print-wrapper .ant-table-wrapper tr    { page-break-inside:avoid; page-break-after:auto }
    .analysis-print-wrapper .ant-table-wrapper thead { display:table-header-group }
    .analysis-print-wrapper .ant-table-wrapper tfoot { display:table-footer-group } */
}
.testPackSelect{
    background: #f2f2f2;
    padding: 15px;
}

.radioSection{
    margin-bottom: 15px;
    padding: 25px;
}
.radioSection .ant-form-item{
    margin-bottom: 0;
}


.testDetails ol,
.testDetails ul{
    padding-left: 20px;
}


.list-sort-demo-wrapper {
    position: relative;
    overflow: hidden;
    height: auto;
  }
  
  .list-sort-demo {
    /* margin: 40px auto; */
    max-width: 100%;
    width: 100%;
    /* cursor: url('http://gtms02.alicdn.com/tps/i2/T1_PMSFLBaXXcu5FDa-20-20.png') 10 10,pointer!important; */
    position: relative;
    height: auto;
  }

  .list-sort-demo > ul {
    padding-left: 0;
  }

  .list-sort-demo > div{
    overflow: hidden;
  }
  
  .list-sort-demo-list {
    background: #fff;
    /* border-radius: 6px; */
    margin: 5px auto;
    padding: 5px 15px;
    height: 30px;
    transition:  box-shadow .5s, transform .5s;
    background: #f2f2f2;
  }
  
  .list-sort-demo-list.list-drag-selected{
    box-shadow: 0 8px 20px #E6E6E6;
    transform: scale(1.1) !important;
  }
  
  /* .list-sort-demo-icon {
    width: 20%;
    display: inline-block;
    text-align: center;
    font-size: 24px;
    line-height: 50px;
    vertical-align: top;
  } */
  .list-sort-demo-text{
    /* width: 80%; */
    display: block;
  }
  
  .list-sort-demo-text h1 {
    font-size: 18px;
  }
  
  .list-sort-demo-text p{
    font-size: 12px;
  }

  .list-sort-demo-text span.sortTestOrder{
    display: inline-block;
    margin-right: 5px;
  }
  .list-sort-demo-text span.sortTestName{
    font-size: 14px;
  }
  
  @media screen and (max-width: 320px) {
    .list-sort-demo-text h1 {
      font-size: 14px;
      line-height: 28px;
    }
  }


.announcement-card-list{}
.announcement-card-list .announcement-card{
    display: flex;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #f2f2f2;
}
.announcement-card-list .announcement-card:last-child{
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
}

.announcement-card-list .announcement-card .card-icon{
    margin-right: 15px;
}
.announcement-card-list .announcement-card .card-icon .anticon{
    font-size: 18px;
    margin-top: 3px;
}
.announcement-card-list .announcement-card .card-content{}
.announcement-card-list .announcement-card .card-content span{
    display: block;
}
.announcement-card-list .announcement-card .card-content span.text{
    font-size: 14px;
}
.announcement-card-list .announcement-card .card-content span.time{
    font-size: 12px;
    color: #6a6a6a;
}


.announcement-card-list .announcement-card .card-action{
    margin-left: auto;
}

.announcement-card-all,
.card-all-button{
    margin-top: 15px;
    text-align: center;
}

