

.announcement-card-list{}
.announcement-card-list .announcement-card{
    display: flex;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #f2f2f2;
}
.announcement-card-list .announcement-card:last-child{
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
}

.announcement-card-list .announcement-card .card-icon{
    margin-right: 15px;
}
.announcement-card-list .announcement-card .card-icon .anticon{
    font-size: 18px;
    margin-top: 3px;
}
.announcement-card-list .announcement-card .card-content{}
.announcement-card-list .announcement-card .card-content span{
    display: block;
}
.announcement-card-list .announcement-card .card-content span.text{
    font-size: 14px;
}
.announcement-card-list .announcement-card .card-content span.time{
    font-size: 12px;
    color: #6a6a6a;
}


.announcement-card-list .announcement-card .card-action{
    margin-left: auto;
}

.announcement-card-all,
.card-all-button{
    margin-top: 15px;
    text-align: center;
}
