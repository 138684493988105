@import url('https://fonts.googleapis.com/css2?family=Baloo+2:wght@400;500;600;700&display=swap');

body{
    font-family: 'Baloo 2', sans-serif;
    background: #f2f2f2;
}


.warning {
    border: solid 1px #fed700
}

.warning-text {
    background: #fed700;
    padding: .24rem .4rem;
    border-radius: 8px;
    width: 100%;
    display: block;
    text-align: center;
}

.danger {
    border: solid 1px #fe0000
}

.danger-text {
    background: #fe0000;
    padding: .24rem .4rem;
    border-radius: 8px;
    width: 100%;
    display: block;
    text-align: center;
}

.ant-layout{
    position: relative;
    z-index: 1;
}
.ant-layout:before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1;
    pointer-events: none;
    height: 400px;
    background-color: #ffc40a;
    /* border-bottom-left-radius: 2.5rem; */
    border-bottom-right-radius: 2.5rem;
}

.layout-container{
    max-width: 1240px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
}


.layout-content{
    min-height: calc(100vh - 200px);
    padding: 24px 0;
    background: transparent;
    color: #fff;
    /* rgb(255, 255, 255); */
}
@media (max-height:500px){
    .layout-content{
        min-height: 100%;
    }
}




.pageHeaderOptions{
}

.text-button{
    color: #252525;
}
.text-button:hover{
    color: #000;
}


.dash-col{
    padding-bottom: 15px;
}
.ant-card{
    border-radius: 8px;
    box-shadow: 0 0 15px rgba(0,0,0,0.1);
    margin-bottom: 25px;
    height: 100%;
}
.ant-card-head{
    border-radius: 8px 8px 0 0;
}
.ant-card .ant-card-type-inner .ant-card-head-title,
.ant-card .ant-card-head-title{
    padding: 16px 0;
    font-size: 16px;
}

.ant-card-extra a{
    color: #6c0aff;
    font-weight: 500;
}

.ant-card .cardIcon{
    position: absolute;
    right: 25px;
    top: 25px;
}
.ant-card .cardIcon .anticon{
    font-size: 20px;
}

.ant-card-body{
    padding: 25px 25px 10px;
}

/*card types*/
.ant-card.cardTable{
    min-height: 400px;
}




/*modal*/
.ant-modal-content{
    border-radius: 10px;
}
.ant-modal-header{
    border-radius: 10px 10px 0 0;
}
.ant-modal-footer{
    border-radius: 0 0 10px 10px;
}



/*dropdown*/
.ant-dropdown{
    /* box-shadow: 0 0 15px rgba(0,0,0,0.1); */
}
.dropdownContent{
    background: #fff;
    border-radius: 10px;
    padding: 25px;
    min-width: 150px;
    max-width: 400px;
    box-shadow: 0 0 15px rgba(0,0,0,0.1);
}
.dropdownContent .dropdownHeader{
    text-align: center;
    margin-bottom: 15px;
    padding-bottom: 5px;
    font-size: 18px;
    font-weight: 700;
    border-bottom: 1px solid #f2f2f2;
}


@media only screen and (max-width: 481px){
    .ant-dropdown{
        width: 100%;
        left: 0 !important;
        padding: 0 10px;

    }
}



/*ant menu*/
.ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left{
    border-right: none;
}





.text-center{
    text-align: center;
}



/*forms*/
.formButton{
}

.ant-select{
    width: 100%;
}

.ant-radio-group{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.ant-radio-wrapper{
    display: flex;
    align-items: center
}

.profileCardProfile{
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content:center;

}
.profileName{
    display: block;
    font-weight: 700;
    color: #000;
    text-align: center;
    font-size: 20px;
    margin-right: 0;
    width: 160px;
}



.btn-w-100{
    width: 100%;
}






/*widget*/
.widget-statistic-flex{
    display: flex;
    justify-content: space-between;
}

.align-center{
    align-items: center;
}
.align-end{
    align-items: flex-end;
}

.widget-icon{
    font-size: 42px;
    color: #6c0aff;
    opacity: 0.5;
}



.notification-card-list{
    margin-bottom: 15px;
}
.notification-card-list .notification-card{
    display: flex;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #f2f2f2;
}
.notification-card-list .notification-card:last-child{
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
}

.notification-card-list .notification-card .card-icon{
    margin-right: 15px;
}
.notification-card-list .notification-card .card-icon .anticon{
    font-size: 18px;
    margin-top: 3px;
}
.notification-card-list .notification-card .card-content{
    margin-right: 10px;
}
.notification-card-list .notification-card .card-content span{
    display: block;
}
.notification-card-list .notification-card .card-content span.text{
    font-size: 14px;
}
.notification-card-list .notification-card .card-content span.time{
    font-size: 12px;
    color: #6a6a6a;
}


.notification-card-list .notification-card .card-action{
    margin-left: auto;
}

.notification-card-all,
.card-all-button{
    margin-top: 15px;
    text-align: center;
}



/*timeline*/
.ant-timeline-item{
    padding-bottom: 10px;
}
.ant-timeline-item-content > span{
    display: flex;
}
.ant-timeline-item-content > span span.time{
    margin-left: auto;
    font-size: 12px;
    color: #6a6a6a;
}



/*table*/


.ant-table-content{
    width: 100%;
    overflow-x: auto;
    overflow-y: none;
}
@media only screen and (max-width: 992px){
    .ant-table-content{
        width: 100%;
        overflow-x: auto;
        overflow-y: none;
    }
}


.ant-spin-blur{
    opacity: 1 !important;
}
.ant-btn{
    border-radius: 30px;
}

/*custom ant*/
.ant-btn-primary{
    background: #ff8300;
    border-color: #ff8300;
}
.ant-btn-primary:hover,
.ant-btn-primary:focus{
    background: #cc6a00;
    border-color: #cc6a00;
}
.ant-btn-primary:active{
    background: #cc6a00;
    border-color: #cc6a00;
}


/*btn-update-class*/
.btn-success{
    background: #9bd006;
    border-color: #9bd006;
}
.btn-success:hover,
.btn-success:focus{
    background: #7ea70b;
    border-color: #7ea70b;
}
.btn-success:active{
    background: #7ea70b;
    border-color: #7ea70b;
}


/* .ant-table-cell .ant-btn-link{
    color: #f00;
}
.ant-table-cell .ant-btn-link:hover,
.ant-table-cell .ant-btn-link:focus{
    color: rgb(200, 0, 0);
} */

.ant-table-column-sorters{
    white-space: nowrap;
}


.ant-btn.btn-add{
    background: #ffffff;
    border-color: #ffffff;
    color: #000;
}
.ant-btn.btn-list{
    background: #ffffff;
    border-color: #ffffff;
    color: #000;
}



@media only screen and (max-width: 574px){
    .ant-btn.btn-add span{
        display: none;
    }
    .ant-btn.btn-add span.anticon{
        display: block;
    }
}


.quickLinks{
}
.quickLinks .ant-btn{
    height: auto;
    padding: 10px;
    border-radius: 6px;
    width: 100%;
    margin-bottom: 16px;
}
.quickLinks .ant-btn .anticon{
    font-size: 22px;
    display: block;
    margin: 10px 0;
}
.quickLinks .ant-btn span.text{
    font-size: 15px;
    display: block;
}


.btn-next-step{
    margin-top: 30px;
    width: 100%;
}



.formButton{
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    margin-top: 25px;
    /* background: #f9f9f9;
    padding: 15px; */
}



/*tags*/
.ant-tag-has-color,
.ant-tag-has-color a,
.ant-tag-has-color a:hover,
.ant-tag-has-color .anticon-close,
.ant-tag-has-color .anticon-close:hover{
    color: #fff;
}

/*
.ant-tag-has-color{
    color: #000;
}
.status-orange{
    background-color: #fd6205;
    border-color: #fd6205;
} */




@media only screen and (min-width: 1441px){
    .layout-container{
        max-width: calc(100% - 30px);
    }
}

@media only screen and (max-width: 1440px){
    .layout-container{
        max-width: calc(100% - 10px);
    }
}


@media only screen and (min-width: 768px){
    .ant-modal{
        min-width: 700px !important;
    }
}

.row-dragging {
    background: #fafafa;
    border: 1px solid #ccc;
  }

  .row-dragging td {
    padding: 16px;
    visibility: hidden;
  }

  .row-dragging .drag-visible {
    visibility: visible;
  }




.description-table .ant-descriptions-item-label,
.description-table .ant-descriptions-item-content{
    padding: 5px 24px;
}

.mt-1{
    margin-top: 16px;
}
.mt-2{
    margin-top: 32px;
}
.mb-1{
    margin-bottom: 16px;
}
.mb-2{
    margin-bottom: 32px;
}

.ml-1{
    margin-left: 16px;
}
.ml-2{
    margin-left: 32px;
}
.mr-1{
    margin-right: 16px;
}
.mr-2{
    margin-right: 32px;
}


.td-title{
    font-weight: 700;
    color: #000;
}


.custom-tag{
    display: inline-flex;
    align-items: center;
    flex-direction: row-reverse;
}
.custom-tag .anticon{
    margin : 0 5px;
}



.descriptionList.ant-descriptions-bordered .ant-descriptions-item-label,
.descriptionList.ant-descriptions-bordered .ant-descriptions-item-content{
    padding: 3px 16px;
}

.ant-form .react-multi-email,
.ant-form-item .react-multi-email{
    border-radius: 2px;
    padding: 4px 10px;
    border: 1px solid #d9d9d9;
}
.ant-form .react-multi-email [data-tag],
.ant-form-item .react-multi-email [data-tag]{
    font-weight: 400;
}


.test-paketleri-all-select .ant-select-item-option-content{}

.ant-select-dropdown .ant-select-item-option-content{
white-space: normal;
}
